const option = [
    { id: 'SCB', name: 'ธนาคารไทยพาณิชย์'},
    { id: 'KBANK', name: 'ธนาคารกสิกรไทย'},
    { id: 'BBL', name: 'ธนาคารกรุงเทพ'},
    { id: 'KTB', name: 'ธนาคารกรุงไทย'},
    { id: 'BAY', name: 'ธนาคารกรุงศรีอยุธยา'},
    { id: 'TTB', name: 'ธนาคารทหารไทย'},
    { id: 'GSB', name: 'ธนาคารออมสิน '}
];

const method = {
    getValueByKey(id) {
        return option.find(x => x.id === id).name;
    }
}

export default {
    option: option,
    service: method
};