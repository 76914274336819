<template>
	<div class="profile" v-if="form">
		<div class="content-yellow-note" v-if="mode === constants.SELLER_PROFILE_MODE.PROFILE">
			<span><b-icon icon="bell" class="icon"></b-icon> หากต้องการแก้ไขข้อมูลในช่องสีเทา กรุณาติดต่อเจ้าหน้าที่ Yellowtire ที่ <a href="mailto:info@yellowtire.com" target="_blank">info@yellowtire.com</a> หรือ +66 (0) 2026 7407</span>
		</div>

		<section class="form mt-4">
			<h2>1. ข้อมูลร้านค้า</h2>
			<div class="row">
				<div class="col col-12 col-sm-6">
					<div class="input-field">
						<label class="require">ชื่อร้าน</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.name"
							maxlength="100"
							class="square lg"
							:class="{ 'invalid': error.name }" />
					</div>
				</div>
				<div class="col col-12 col-sm-6">
					<div class="input-field">
						<label class="require">ชื่อบริษัท ตามหนังสือรับรอง (สำหรับออกเอกสารทางบัญชี)</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.dbdCode"
							maxlength="100"
							class="square lg" />
					</div>
				</div>
				<div class="col col-12 col-sm-6" v-if="form.branchOption !== '1'">
					<div class="input-field">
						<label>ชื่อสาขา</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.branchName"
							maxlength="100"
							class="square lg" />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col col-12">
					<b-form-group class="branch-option">
						<div class="item">
							<div class="field-option">
								<b-form-radio v-model="form.branchOption" name="appointment-time" value="1" class="input-radio" :disabled="mode === constants.SELLER_PROFILE_MODE.PROFILE"
								>สำนักงานใหญ่</b-form-radio>
							</div>
							<div class="text">
								(กรณีมีสาขาเดียว หรือ หลายสาขา โดยชื่อบริษัท/เลขประจำตัวผู้เสียภาษีแยกกัน)
							</div>
						</div>
						<div class="item">
							<div class="field-option">
								<b-form-radio v-model="form.branchOption" name="appointment-time" value="2" class="input-radio" :disabled="mode === constants.SELLER_PROFILE_MODE.PROFILE">สำนักงานใหญ่</b-form-radio>
							</div>
							<div class="text">
								(กรณีเป็นสำนักงานใหญ่ และมีหลายสาขา โดยชื่อบริษัท/เลขประจำตัวผู้เสียภาษีเหมือนกัน)
							</div>
						</div>
						<div class="item">
							<div class="field-option">
								<b-form-radio v-model="form.branchOption" name="appointment-time" value="3" class="input-radio" :disabled="mode === constants.SELLER_PROFILE_MODE.PROFILE">สาขาที่</b-form-radio>
								<input type="text"
									v-model="form.branchNumber"
									maxlength="5"
									:readonly="form.branchOption !== '3' || mode === constants.SELLER_PROFILE_MODE.PROFILE" />
							</div>
							<div class="text">
								(กรณีเป็นสาขาย่อย โดยชื่อบริษัท/เลขประจำตัวผู้เสียภาษีเหมือนกัน) *โปรดกำหนดโดยการระบุเป็นเลข 5 หลักเช่น 00001
							</div>
						</div>
					</b-form-group>
				</div>
			</div>

			<div class="content-yellow-note mb-3">
				<span><b-icon icon="bell" class="icon"></b-icon> หากร้านค้าของท่านมีหลายสาขาและต้องการใช้บัญชีเดียวในการบริหารจัดการร้านค้าสาขาอื่นๆ โปรดทำการลงทะเบียนสาขาหลัก จากนั้นแจ้งขอความประสงค์ในการเปิดสาขาอื่นๆโดยขอใช้บัญชีเดียวได้ที่ info@yellowtire.com หรือ +66 (0) 2026 7407</span>
			</div>

			<div class="row">
				<div class="col col-12 col-sm-6">
					<div class="input-field">
						<label>ทะเบียนนิติบุคคล/เลขประจำตัวผู้เสียภาษี</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.taxId"
							maxlength="100"
							class="square lg" />
					</div>
				</div>
				<div class="col col-12 col-sm-3">
					<div class="input-field">
						<label>&nbsp;</label>
						<b-form-group>
							<b-form-radio v-model="form.requireTax" name="canRequestTax" :value="true" :disabled="mode === constants.SELLER_PROFILE_MODE.PROFILE">ออกใบกำกับภาษีได้</b-form-radio>
							<b-form-radio v-model="form.requireTax" name="canRequestTax" :value="false" :disabled="mode === constants.SELLER_PROFILE_MODE.PROFILE">ออกใบกำกับภาษีไม่ได้</b-form-radio>
						</b-form-group>
					</div>
				</div>
				<div class="col col-12 col-sm-3">
					<div class="input-field">
						<label class="d-none d-sm-block">&nbsp;</label>
						<div class="checkbox-etax">
							<div class="blocker" v-if="!form.requireTax"></div>
							<checkbox 
								v-model="form.isAllowElecTax">
								ออกใบกำกับภาษีอิเล็กทรอนิกส์ e-Tax Invoice/e-Receipt ได้
							</checkbox>
						</div>
					</div>
				</div>
				<div class="col col-12 col-sm-6">
					<div class="input-field">
						<label class="require">ชื่อ-นามสกุล ผู้มีอำนาจ</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.ownerName"
							maxlength="100"
							class="square lg"
							:class="{ 'invalid': error.ownerName }" />
					</div>
				</div>
				<div class="col col-12 col-sm-6">
					<div class="input-field">
						<label>ตำแหน่ง</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.ownerPosition"
							maxlength="100"
							class="square lg" />
					</div>
				</div>
				<div class="col col-12 col-sm-6">
					<div class="input-field">
						<label class="require">ชื่อ-นามสกุล ผู้ติดต่อ</label>
						<input type="text"
							v-model="form.contactName"
							maxlength="100"
							class="square lg"
							:class="{ 'invalid': error.contactName }" />
					</div>
				</div>
				<div class="col col-12 col-sm-6">
					<div class="input-field">
						<label>ตำแหน่ง</label>
						<input type="text"
							v-model="form.contactPosition"
							maxlength="100"
							class="square lg" />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col col-12 col-sm-4">
					<div class="input-field">
						<label class="require">เบอร์โทรศัพท์ร้านค้า</label>
						<input type="tel"
							@keypress="$AppUtility.inputOnlyNumber($event)"
							v-model="form.tel"
							maxlength="12"
							class="square lg"
							:class="{ 'invalid': error.tel }" />
					</div>
				</div>
				<div class="col col-12 col-sm-4">
					<div class="input-field">
						<label>เบอร์โทรศัพท์ร้านค้า 2</label>
						<input type="tel"
							@keypress="$AppUtility.inputOnlyNumber($event)"
							v-model="form.tel2"
							maxlength="12"
							class="square lg" />
					</div>
				</div>
				<div class="col col-12 col-sm-4">
					<div class="input-field">
						<label>LineID ร้านค้า</label>
						<input type="text"
							v-model="form.line"
							maxlength="20"
							placeholder="@line"
							class="square lg" />
					</div>
				</div>
				<div class="col col-12 col-sm-4">
					<div class="input-field">
						<label class="require">อีเมล</label>
						<input type="text"
							v-model="form.email"
							maxlength="100"
							class="square lg"
							:class="{ 'invalid': error.email }" />
					</div>
				</div>
				<div class="col col-12 col-sm-4">
					<div class="input-field">
						<label>เว็บไซต์</label>
						<input type="text"
							v-model="form.website"
							maxlength="100"
							placeholder="https://..."
							class="square lg" />
					</div>
				</div>
				<div class="col col-12 col-sm-4">
					<div class="input-field">
						<label>facebook</label>
						<input type="text"
							v-model="form.facebook"
							maxlength="100"
							placeholder="https://www.facebook.com/...."
							class="square lg" />
					</div>
				</div>
			</div>

			<div class="sub-title">ชื่อ URL ร้านค้าใน Yellowtire</div>
			<div class="sub-remark mb-2">* ใช้สำหรับแสดงชื่อ URL เวลามีคนเข้าเยี่ยมชมหน้าร้านผ่านเว็บไซต์ ตัวอย่างเช่นตั้งชื่อ</div>

			<div class="row">
				<div class="col col-12 col-sm-6 col-md-4">
					<gadget-policy-input :showUrlNote="true" class="mb-3"></gadget-policy-input>
				</div>
			</div>

			<div class="row">
				<div class="col col-12 col-sm-6 col-md-4">
					<div class="input-field">
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.urlAlias"
							maxlength="50"
							class="square lg"
							:class="{ 'invalid': error.urlAlias }" />
					</div>
				</div>
				<div class="col col-12 col-sm-6 col-md-8">
					<div class="sub-remark">
						ตัวอย่างการแสดงผล
						<br />
						<span>https://www.yellowtire.com/store/x-y.{{ form.urlAlias ? form.urlAlias : 'example' }}</span> 
						<br />
						* x-y จะเป็นตัวเลขที่ได้จากระบบ
					</div>
				</div>
			</div>

			<div class="sub-title">บัญชีธนาคาร</div>

			<div class="row">
				<div class="col col-12 col-sm-4">
					<div class="input-field">
						<label class="require">ชื่อธนาคาร</label>
						<dropdown
							:disabled="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.bankName"
							placeholder="-- เลือกธนาคาร --"
							:options="optionBank"
							:classes="{ input: ['square', 'lg', (error.bankName ? 'invalid' : '')] }">
						</dropdown>
					</div>
				</div>
				<div class="col col-12 col-sm-4">
					<div class="input-field">
						<label class="require">เลขที่บัญชี</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.accountNumber"
							maxlength="100"
							class="square lg"
							:class="{ 'invalid': error.accountNumber }" />
					</div>
				</div>
				<div class="col col-12 col-sm-4">
					<div class="input-field">
						<label class="require">ชื่อบัญชี</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.accountName"
							maxlength="100"
							class="square lg"
							:class="{ 'invalid': error.accountName }" />
					</div>
				</div>
			</div>
		</section>
		<section class="form mt-5">
			<h2>2. รายละเอียดร้านค้า</h2>

			<div class="day-prepare">
				<h5>จำนวนวันเพื่อจัดเตรียมสินค้า</h5>
				<div class="sub-remark">
					ใช้สำหรับตอนที่ลูกค้าเลือกวันนัดหมายที่ใกล้ที่สุด ซึ่งจะบวกจำนวนวันที่นับจากวันที่ทำรายการ
				</div>

				<div class="row">
					<div class="col-sm-12 col-md-4">
						<div class="input-field">
							<select
								v-model="form.dayPrepareService"
								class="square"
								:class="{ 'invalid': error.dayPrepareService }">
								<option v-for="(item, index) in optionDays" :key="index" :value="item.key">
									{{ item.name }}
								</option>
							</select>
							<div class="text">
								แบบมีสต๊อค
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-4">
						<div class="input-field">
							<select
								v-model="form.dayPrepareServiceSublet"
								class="square"
								:class="{ 'invalid': error.dayPrepareServiceSublet }">
								<option v-for="(item, index) in optionDays" :key="index" :value="item.key">
									{{ item.name }}
								</option>
							</select>
							<div class="text">
								สินค้าสั่งจากร้านอื่น
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-4">
						<div class="input-field">
							<select
								v-model="form.dayPrepareServiceFactory"
								class="square"
								:class="{ 'invalid': error.dayPrepareServiceFactory }">
								<option v-for="(item, index) in optionDays" :key="index" :value="item.key">
									{{ item.name }}
								</option>
							</select>
							<div class="text">
								สินค้าสั่งจากโรงงาน
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="service-info">
				<h5>บริการที่มี</h5>

				<div class="wrapper">
					<div class="item" v-for="(item, index) in checkListServiceInfo" :key="index">
						<div class="checkbox">
							<checkbox 
								v-model="form.serviceInfo[item.property]"
								class="checker-large">
								{{ item.text }}
							</checkbox>
						</div>
					</div>
				</div>
			</div>

			<div class="opening-time">
				<div class="item" v-for="(item, index) in openingTime" :key="index">
					<div class="checkbox">
						<div class="blocker" v-if="form.openAt.all.isOpen && item.key !== 'all'"></div>
						<checkbox 
							v-model="form.openAt[item.key].isOpen"
							class="checker-large"
							@change="toggleOpeningTime($event, item)">
							{{ item.text }}
						</checkbox>
					</div>
					<div class="time">
						<div class="blocker" v-if="(form.openAt.all.isOpen && item.key !== 'all') || !form.openAt[item.key].isOpen"></div>
						<div class="input-field">
							<label>เวลาเปิด</label>
							<timepicker
								class="time"
								:inputClass="[ 'square' ]"
								v-model="form.openAt[item.key].openTime">
							</timepicker>
						</div>
					</div>
					<div class="time">
						<div class="blocker" v-if="(form.openAt.all.isOpen && item.key !== 'all') || !form.openAt[item.key].isOpen"></div>
						<div class="input-field">
							<label>เวลาปิด</label>
							<timepicker
								class="time"
								:inputClass="[ 'square' ]"
								v-model="form.openAt[item.key].closeTime">
							</timepicker>
						</div>
					</div>
				</div>
			</div>

			<div class="sub-title">ที่อยู่/พิกัดร้านค้า</div>

			<div class="row">
				<div class="col col-12 col-sm-6 col-md-4">
					<div class="input-field">
						<label class="require">เลขที่/หมู่</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.addressNo"
							maxlength="10"
							class="square lg"
							:class="{ 'invalid': error.addressNo }" />
					</div>
				</div>
				<div class="col col-12 col-sm-6 col-md-4">
					<div class="input-field">
						<label>ซอย</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.soi"
							maxlength="100"
							class="square lg" />
					</div>
				</div>
				<div class="col col-12 col-sm-6 col-md-4">
					<div class="input-field">
						<label class="require">ถนน</label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.street"
							maxlength="100"
							class="square lg"
							:class="{ 'invalid': error.street }" />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col col-12 col-sm-6 col-md-3">
					<div class="input-field">
						<label class="require">ตำบล/แขวง</label>
						<address-auto-complete
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							ref="thaiAddressDataTumbon"
							:listData="mixinsThaiAddressList"
							v-model="form.subDistrict"
							:inputClass="[(error.subDistrict ? 'invalid' : ''), 'square', 'lg']"
							@hit="mixinsThaiAddressSetThaiAddressValue($event)"
							@input="mixinsThaiAddressSetAutoComplete($event, 'tumbon')"
							@blur="mixinsThaiAddressCheckValidInputAddress($event, 'tumbon')" />
					</div>
				</div>
				<div class="col col-12 col-sm-6 col-md-3">
					<div class="input-field">
						<label class="require">อำเภอ/เขต</label>
						<address-auto-complete
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							ref="thaiAddressDataAmphur"
							:listData="mixinsThaiAddressList"
							v-model="form.district"
							:inputClass="[(error.district ? 'invalid' : ''), 'square', 'lg']"
							@hit="mixinsThaiAddressSetThaiAddressValue($event)"
							@input="mixinsThaiAddressSetAutoComplete($event, 'amphur')"
							@blur="mixinsThaiAddressCheckValidInputAddress($event, 'amphur')" />
					</div>
				</div>
				<div class="col col-12 col-sm-6 col-md-3">
					<div class="input-field">
						<label class="require">จังหวัด</label>
						<address-auto-complete
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							ref="thaiAddressDataProvince"
							:listData="mixinsThaiAddressList"
							v-model="form.province"
							:inputClass="[(error.province ? 'invalid' : ''), 'square', 'lg']"
							@hit="mixinsThaiAddressSetThaiAddressValue($event)"
							@input="mixinsThaiAddressSetAutoComplete($event, 'province')"
							@blur="mixinsThaiAddressCheckValidInputAddress($event, 'province')" />
					</div>
				</div>
				<div class="col col-12 col-sm-6 col-md-3">
					<div class="input-field">
						<label class="require">รหัสไปรษณีย์</label>
						<address-auto-complete
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							ref="thaiAddressDataPostCode"
							:listData="mixinsThaiAddressList"
							v-model="form.postCode"
							:inputClass="[(error.postCode ? 'invalid' : ''), 'square', 'lg']"
							@hit="mixinsThaiAddressSetThaiAddressValue($event)"
							@input="mixinsThaiAddressSetAutoComplete($event, 'postcode')"
							@blur="mixinsThaiAddressCheckValidInputAddress($event, 'postcode')" />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col col-12">
					<div class="input-field">
						<label class="require">จุดสังเกตร้าน</label>
						<input type="text"
							v-model="form.pointOfInterest"
							maxlength="100"
							class="square lg"
							:class="{ 'invalid': error.pointOfInterest }" />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col col-12">
					<div class="input-field">
						<label class="require">แผนที่ร้าน Google Map (<a href="/pdf/Yellowtire-Guide-GoogleMap.pdf" target="_blank">อ่านวิธีคัดลอก Google Map)</a></label>
						<input type="text"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							maxlength="1000"
							v-model="form.googleMap"
							@change="changeGoogleMapUrl($event)"
							class="square lg"
							:class="{ 'invalid': error.googleMap }" />
					</div>
					<div class="google-map" v-if="googleMapUrl">
						<iframe :src="googleMapUrl"></iframe>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col col-12 col-sm-6">
					<div class="input-field">
						<label>Latitude</label>
						<input type="tel"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.latitude"
							maxlength="100"
							class="square lg" />
					</div>
				</div>
				<div class="col col-12 col-sm-6">
					<div class="input-field">
						<label>Longtitude</label>
						<input type="tel"
							:readonly="mode === constants.SELLER_PROFILE_MODE.PROFILE"
							v-model="form.longtitude"
							maxlength="100"
							class="square lg" />
					</div>
				</div>
			</div>
		</section>

		<div class="text-center mt-4">
			<button class="btn btn-main btn-submit" @click="submitSellerProfile()">{{ mode === constants.SELLER_PROFILE_MODE.REGISTER ? 'สมัครร้านค้า' : 'แก้ไขข้อมูล' }}</button>
		</div>
	</div>
</template>

<script>
import ThaiAddress from '@/mixins/thaiAddress';
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import NetworkService from '@/services/networkService';
import OptionBankName from '@/services/staticOption/bankName';
import Constants from '@/variables/constants';
import RouterPath from '@/router/path';
import GadgetPolicyInput from '@/components/gadget/PolicyInput';

export default {
	mixins: [ ThaiAddress ],
	components: { GadgetPolicyInput },
	data() {
		return {
			openingTime: this.openTime(),
			load: null,
			form: null,
			error: this.initError(),
			checkListServiceInfo: Helper.getServiceInfoCheckbox(),
			googleMapUrl: '',
			optionNetwork: [],
			optionDays: Helper.getOptionGapDay(),
			optionBank: OptionBankName.option,
			constants: Constants,
			selectedBranchOption: 1
		}
	},
	props: {
		mode: {
            type: Number,
            default: () => Constants.SELLER_PROFILE_MODE.REGISTER
        }
	},
	mounted() {
		this.initPage();
	},
	methods: {
		async initPage() {
			let loader = this.$modalLoader.render();
			await this.setupNetworkOption();
			await this.loadProfile();
			this.form = this.initForm();
			loader.hide();

			if (this.mode === Constants.SELLER_PROFILE_MODE.PROFILE) {
				this.formulateGoogleMapUrl(this.form.googleMap);
			}
		},
		async setupNetworkOption() {
			// Network
			const result = await NetworkService.getNetworkList();

			result.data.forEach((item) => {
				this.optionNetwork.push({
					id: item.id,
					name: item.title
				});
			});
		},
		async loadProfile() {
			if (this.mode === Constants.SELLER_PROFILE_MODE.PROFILE) {
				let loader = this.$modalLoader.render();
				const result = await SellerService.getProfile();

				if (result.status == 401) {
					this.$router.push(RouterPath.SELLER_LOGIN);
				} else if (result.data) {
					this.load = result.data;
				}

				loader.hide();
			}
		},
		openTime() {
			return [
				{ text: 'เปิด ทำการทุกวัน', key: 'all' },
				{ text: 'เปิด วันจันทร์', key: 'mon' },
				{ text: 'เปิด วันอังคาร', key: 'tue' },
				{ text: 'เปิด วันพุธ', key: 'wed' },
				{ text: 'เปิด วันพฤหัสบดี', key: 'thu' },
				{ text: 'เปิด วันศุกร์', key: 'fri' },
				{ text: 'เปิด วันเสาร์', key: 'sat' },
				{ text: 'เปิด วันอาทิตย์', key: 'sun' }
			];
		},
		initForm() {
			const form = {
				name: this.load ? this.load.name : '',
				network: this.load ? this.load.network : '',
				requireTax: this.load ? this.load.requireTax : false,
				isAllowElecTax: this.load ? this.load.isAllowElecTax : false,
				taxId: this.load ? this.load.taxId : '',
				dbdCode: this.load ? this.load.dbdCode : '',
				branchOption: this.load ? this.load.branchOption : '1',
				branchNumber: this.load ? this.load.branchNumber : '',
				ownerName: this.load ? this.load.ownerName : '',
				ownerPosition: this.load ? this.load.ownerPosition : '',
				contactName: this.load ? this.load.contactName : '',
				contactPosition: this.load ? this.load.contactPosition : '',
				tel: this.load ? this.load.tel : '',
				tel2: this.load ? this.load.tel2 : '',
				line: this.load ? this.load.line : '',
				email: this.load ? this.load.email : '',
				website: this.load ? this.load.website : '',
				facebook: this.load ? this.load.facebook : '',
				urlAlias: this.load ? this.load.urlAlias : '',
				bankName: this.load ? this.load.bankName : '',
				accountNumber: this.load ? this.load.accountNumber : '',
				accountName: this.load ? this.load.accountName : '',
				isEnablePrepaid: this.load ? this.load.isEnablePrepaid : false,
				dayPrepareService: this.load ? this.load.dayPrepareService?.toString() : '1',
				dayPrepareServiceSublet: this.load ? this.load.dayPrepareServiceSublet?.toString() : '3',
				dayPrepareServiceFactory: this.load ? this.load.dayPrepareServiceFactory?.toString() : '7',
				serviceInfo: {},
				openAt: {},
				addressNo: this.load ? this.load.addressNo : '',
				soi: this.load ? this.load.soi : '',
				street: this.load ? this.load.street : '',
				subDistrict: this.load ? this.load.subDistrict : '',
				district: this.load ? this.load.district : '',
				province: this.load ? this.load.province : '',
				postCode: this.load ? this.load.postCode : '',
				pointOfInterest: this.load ? this.load.pointOfInterest : '',
				latitude: this.load ? this.load.latitude : '',
				longtitude: this.load ? this.load.longtitude : '',
				googleMap: this.load ? this.load.googleMap : ''
			};

			// Service Info
			this.checkListServiceInfo.forEach((item) => {
				form.serviceInfo[item.property] = this.load ? this.load.serviceInfo[item.property] : false;
			});

			// Open Time
			this.openingTime.forEach((item) => {
				form.openAt[item.key] = {
					isOpen: this.load ? this.load.openAt[item.key].isOpen : false,
					openTime: this.load ? this.load.openAt[item.key].openTime : '',
					closeTime: this.load ? this.load.openAt[item.key].closeTime : ''
				};
			});

			return form;
		},
		initError() {
			return {
				name: false,
				ownerName: false,
				contactName: false,
				tel: false,
				email: false,
				urlAlias: false,
				bankName: false,
				accountNumber: false,
				accountName: false,
				dayPrepareService: false,
				dayPrepareServiceSublet: false,
				dayPrepareServiceFactory: false,
				addressNo: false,
				street: false,
				subDistrict: false,
				district: false,
				province: false,
				postCode: false,
				pointOfInterest: false,
				googleMap: false
			}
		},
		toggleOpeningTime(value, item) {
			if (!value) {
				this.form.openAt[item.key].openTime = '';
				this.form.openAt[item.key].closeTime = '';
			}

			if (item.key === 'all') {
				if (value) {
					for (const property in this.form.openAt) {
						if (property !== 'all') {
							this.form.openAt[property] = {
								isOpen: false,
								openTime: '',
								closeTime: ''
							};
						}
					}
				}
			}
		},
		changeGoogleMapUrl(e) {
			this.formulateGoogleMapUrl(e.target.value);
		},
		formulateGoogleMapUrl(url) {
			let output = '';

			if (url.indexOf('iframe') > -1) {
				const src = url.split('src=')[1].split(/[ >]/)[0];
				output = src.replace(/"/g, '');
			} else if (url.indexOf('http') === 0) {
				output = url;
			} else if (!url) {
				output = '';
			} else {
				this.$swal(Helper.warningAlert('รูปแบบ Google Map ไม่ถูกต้อง', 'โปรดอ่านวิธีคัดลอก Google Map'));
			}

			if (output) {
				const data = output.split('!');

				this.form.latitude = data[6].indexOf('3d') > -1 ? data[6].replace('3d', '') : '';
				this.form.longtitude = data[5].indexOf('2d') > -1 ? data[5].replace('2d', '') : '';
			}

			this.googleMapUrl = output;
			this.form.googleMap = output;
		},
		async submitSellerProfile() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
				let result = {};

				if (this.mode === Constants.SELLER_PROFILE_MODE.REGISTER) {
					result = await SellerService.registerStore(this.form);
				} else {
					result = await SellerService.saveStoreData(this.form);
				}

				if (result.data?.success) {
					location.reload();
				} else if (result.data?.invalidToken) {
					loader.hide();
					SellerService.clearSellerToken();
					this.$swal(Helper.warningAlert('ยังไม่สามารถบันทึกข้อมูลได้', result.data.message))
						.then(() => {
							this.$router.push(RouterPath.SELLER_REGISTER);
						});
				} else {
					loader.hide();
					this.$swal(Helper.warningAlert('ยังไม่สามารถบันทึกข้อมูลได้', result.data?.message));
				}
			}
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.error) {
				const data = this.form[property] ? this.form[property].toString() : '';

                if (!data.trim()) {
                    this.error[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถบันทึกข้อมูลได้', 'โปรดกรอกข้อมูลในกล่องสีแดงให้ครบถ้วน'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.form {
	border-bottom: 1px solid #ccc;
	padding-bottom: 40px;

	.row {
		margin-left: -7px;
		margin-right: -7px;

		.col {
			padding-left: 7px;
			padding-right: 7px;
		}
	}

	&:last-child {
		border-bottom: none;
		padding-bottom: 0;
	}

	h2 {
		font-size: 22px;
		font-family: 'Open Sans', 'Kanit';
		font-weight: bold;
		margin-bottom: 25px;
		color: #555;

		@media only screen and (max-width: $screenLarge) {
			font-size: 24px;
		}
	}

	label {
		font-weight: normal;
		font-size: 13px;

		@media only screen and (max-width: $screenSmall) {
			height: auto;
		}
	}

	.branch-option {
		margin-bottom: 20px;

		.item {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 100%;
			min-height: 36px;

			@media only screen and (max-width: $screenExtraSmall) {
				margin-bottom: 5px;
			}

			.field-option {
				display: flex;
				align-items: center;
				width: 180px;

				.input-radio {
					white-space: nowrap;
					max-width: 150px;
				}

				input {
					width: 80px;
					height: 34px;
					font-size: 13px;
					margin-left: 10px;
					border: 1px solid #999;
					background: #fff;
					text-align: center;

					&[readonly] {
						background: #eee;
					}
				}
			}

			.text {
				flex: 1;
				font-size: 13px;
				line-height: 15px;
			}
		}
	}

	.input-field {
		margin-bottom: 10px;
	}

	.checkbox-etax {
		position: relative;

		.blocker {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #fff;
			opacity: 0.6;
			z-index: 1;
			cursor: not-allowed;
		}
	}

	.sub-title {
		margin: 20px 0 5px;
		font-size: 20px;
		font-weight: bold;

		@media only screen and (max-width: $screenExtraSmall) {
			font-size: 16px;
		}
	}

	.google-map {
		margin-top: 10px;
		height: 300px;

		iframe {
			width: 100%;
			height: 100%;
		}
	}

	h5 {
		font-weight: bold;
		font-size: 15px;
		margin-bottom: 5px;
	}

	.sub-remark {
		font-size: 13px;
		line-height: 15px;
		opacity: 0.8;
		margin-bottom: 10px;

		span {
			color: #0093cb;
		}
	}

	.prepaid {
		margin: 0 0 30px 30px;
		padding-bottom: 30px;
		border-bottom: 1px dashed #ccc;

		.checkbox {
			position: relative;

			.blocker {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: #fff;
				opacity: 0.6;
				z-index: 1;
				cursor: not-allowed;
			}
		}
	}

	.day-prepare {
		margin: 0 0 30px 30px;
		padding-bottom: 30px;
		border-bottom: 1px dashed #ccc;

		@media only screen and (max-width: $screenExtraSmall) {
			margin-left: 0;
		}

		.input-field {
			display: flex;
			align-items: center;
			
			select {
				max-width: 100px;
				width: 100%;
				margin-right: 20px;
			}

			.text {
				flex: 1;
				font-size: 13px;
				line-height: 15px;
				opacity: 0.8;
			}
		}
	}

	.service-info {
		margin: 0 0 30px 30px;
		padding-bottom: 30px;
		border-bottom: 1px dashed #ccc;

		@media only screen and (max-width: $screenExtraSmall) {
			margin-left: 0;
		}

		.wrapper {
			display: flex;
			flex-wrap: wrap;

			.item {
				width: 300px;
				margin-bottom: 5px;

				@media only screen and (max-width: $screenExtraSmall) {
					width: 100%;
				}
			}
		}
	}

	.opening-time {
		margin-bottom: 30px;

		.item {
			display: flex;
			flex-wrap: wrap;

			.blocker {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: #fff;
				opacity: 0.6;
				z-index: 1;
				cursor: not-allowed;
			}

			.checkbox {
				position: relative;
				width: 30%;
				display: flex;
				align-items: flex-start;
				padding-left: 30px;

				@media only screen and (max-width: $screenSmall) {
					padding-left: 0;
				}

				@media only screen and (max-width: $screenExtraSmall) {
					width: 100%;
				}
			}

			.time {
				position: relative;
				width: 35%;

				@media only screen and (max-width: $screenExtraSmall) {
					width: 50%;
				}

				.input-field {
					display: flex;
					align-items: center;
					
					label {
						text-align: right;
						width: 40%;
						padding-right: 15px;

						@media only screen and (max-width: $screenExtraSmall) {
							font-size: 12px;
						}
					}

					input, .time {
						width: 60%;
					}
				}
			}
		}
	}
}

.btn-submit {
	width: 200px;
	height: 50px;
	font-size: 18px;
	@include boxShadow(2px 2px 5px rgb(0 0 0 / 15%));
}

::v-deep .checker-large {
	.checkbox-button {
		width: 35px;
		height: 35px;

		&.checked {
			font-size: 28px;
		}

		@media only screen and (max-width: $screenExtraSmall) {
			width: 25px;
			height: 25px;

			&.checked {
				font-size: 22px;
			}
		}
	}

	.checkbox-label {
		color: #666;
		font-size: 13px;

		@media only screen and (max-width: $screenExtraSmall) {
			font-size: 12px;
		}
	}
}

::v-deep .checkbox-control {
	align-items: center;
}

::v-deep .form {
	.input-field {
		.checkbox-label {
			@media only screen and (max-width: $screenSmall) {
				font-size: 12px;
			}
		}
		
		input {
			@media only screen and (max-width: $screenSmall) {
				font-size: 14px;
			}
		}
	}
}

::v-deep .custom-radio {
	.custom-control-label {
		font-size: 13px;
	}
}
</style>